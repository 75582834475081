import { useEffect, useState } from 'react'
import rampStore from '..'
import { reaction } from 'mobx'

const useRampStore = () => {
  const [historyList, setHistoryList] = useState(() => rampStore.historyList)
  const [supportCryptoList, setList] = useState(
    () => rampStore.supportCryptoList
  )
  const [selectedToken, setSelectToken] = useState(
    () => rampStore.selectedToken
  )

  const [buyQuote, setBuyQuote] = useState(() => rampStore.buyQuote)
  const [buyQuotes, setBuyQuotes] = useState(() => rampStore.buyQuotes)
  const [buySelectedCurrency, setBuySelectedCurrency] = useState(
    () => rampStore.buySelectedCurrency
  )
  const [buyAmount, setBuyAmount] = useState(() => rampStore.buyAmount)

  useEffect(() => {
    const listReaction = reaction(
      () => rampStore.historyList,
      (v) => setHistoryList(v)
    )
    const cryptoReaction = reaction(
      () => rampStore.supportCryptoList,
      (v) => setList(v)
    )
    const selectReaction = reaction(
      () => rampStore.selectedToken,
      (v) => setSelectToken(v)
    )
    const buyQuoteReaction = reaction(
      () => rampStore.buyQuote,
      (v) => setBuyQuote(v)
    )
    const buyQuotesReaction = reaction(
      () => rampStore.buyQuotes,
      (v) => setBuyQuotes(v)
    )
    const buySelectedCurrencyReaction = reaction(
      () => rampStore.buySelectedCurrency,
      (v) => setBuySelectedCurrency(v)
    )
    const buyAmountReaction = reaction(
      () => rampStore.buyAmount,
      (v) => setBuyAmount(v)
    )

    return () => {
      listReaction()
      cryptoReaction()
      selectReaction()
      buyQuoteReaction()
      buyQuotesReaction()
      buySelectedCurrencyReaction()
      buyAmountReaction()
    }
  }, [])
  return {
    buyQuote,
    buyQuotes,
    historyList,
    selectedToken,
    supportCryptoList,
    buySelectedCurrency,
    buyAmount
  }
}

export default useRampStore
